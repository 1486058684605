export default function (rootElement) {

  if (!rootElement) {
    return;
  }

  const tabs = Array.from(rootElement.querySelectorAll("[role='tab']"));
  const tabPanels = rootElement.querySelectorAll("[role='tabpanel']")

  if (!tabs || tabs.length === 0) {
    return;
  }

  function setSelectedTab(newTab) {
    for (const [i, tab] of tabs.entries()) {
      const tabPanel = tabPanels[i];
      if (newTab === tab) {
        tab.setAttribute('aria-selected', 'true');
        tab.removeAttribute("tabIndex");
        tabPanel.removeAttribute('hidden');
      } else {
        tab.setAttribute('aria-selected', 'false');
        tab.tabIndex = -1;
        tabPanel.setAttribute('hidden', 'true');
      }
    }
  }

  for (const [i, tab] of tabs.entries()) {
    tab.addEventListener("click", () => {
      setSelectedTab(tab);
    })

    tab.addEventListener("keydown", (ev) => {
      let newTab = null;

      switch (ev.key) {
        case "ArrowLeft":
          if (tab === tabs[0]) {
            newTab = tabs.at(-1);
          } else {
            newTab = tabs[tabs.indexOf(tab) - 1]
          }
          break;
        case "ArrowRight":
          if (tab === tabs.at(-1)) {
            newTab = tabs[0];
          } else {
            newTab = tabs[tabs.indexOf(tab) + 1]
          }
          break;
        case "Home":
          newTab = tabs[0];
          break;
        case "End":
          newTab = tabs.at(-1);
          break;
      }

      if (newTab) {
        newTab.focus();
        ev.stopPropagation();
        ev.preventDefault();
      }
    })
  }

}